import {
  FacebookDate,
  InsightsFacebookSelectableDatePreset,
} from '@magicbrief/common';
import { captureException } from '@sentry/react';
import dayjs from 'src/lib/dayjs';

export const getSinceAndUntilDates = (
  datePreset: InsightsFacebookSelectableDatePreset
): { since: FacebookDate; until: FacebookDate } => {
  const now = dayjs();
  let until: dayjs.Dayjs;
  let since: dayjs.Dayjs;

  switch (datePreset) {
    case 'today':
      /** Current day */
      until = since = now;
      break;
    case 'yesterday':
      /** Prior day */
      until = since = now.subtract(1, 'day');
      break;
    case 'last_3d':
      /** 3 days prior to current day (exclusive) */
      since = now.subtract(3, 'day');
      until = now.subtract(1, 'day');
      break;
    case 'last_7d':
      /** 7 days prior to current day (exclusive) */
      since = now.subtract(7, 'day');
      until = now.subtract(1, 'day');
      break;
    case 'this_week_sun_today':
      /** Most recent Sunday to today (inclusive) */
      since = now.startOf('week');
      until = now;
      break;
    case 'last_week_sun_sat':
      /** Week prior from Sunday to Saturday (inclusive) */
      since = now.subtract(1, 'week').startOf('week');
      until = now.subtract(1, 'week').endOf('week');
      break;
    case 'last_14d':
      /** 14 days prior to current day (exclusive) */
      since = now.subtract(14, 'day');
      until = now.subtract(1, 'day');
      break;
    case 'last_28d':
      /** 28 days prior to current day (exclusive) */
      since = now.subtract(28, 'day');
      until = now.subtract(1, 'day');
      break;
    case 'last_30d':
      /** 30 days prior to current day (exclusive) */
      since = now.subtract(30, 'day');
      until = now.subtract(1, 'day');
      break;
    case 'this_month':
      /** From first day of the current month to today (inclusive) */
      since = now.startOf('month');
      until = now;
      break;
    case 'last_month':
      /** From first day to last day of the prior month (inclusive) */
      since = now.subtract(1, 'month').startOf('month');
      until = now.subtract(1, 'month').endOf('month');
      break;
    case 'last_quarter':
      /** From first day to last day of the most recently completed calendar  */
      since = now.subtract(1, 'quarter').startOf('quarter');
      until = now.subtract(1, 'quarter').endOf('quarter');
      break;
    case 'this_year':
      /** From first day of the current year to today (inclusive) */
      since = now.startOf('year');
      until = now;
      break;
    case 'last_year':
      /** From first day to last day of prior year (inclusive) */
      since = now.subtract(1, 'year').startOf('year');
      until = now.subtract(1, 'year').endOf('year');
      break;
    case 'maximum':
      /** From current day minus 3 years and 1 month to current day (inclusive) */
      since = now.subtract(3, 'year').subtract(1, 'month');
      until = now;
      break;
    case 'last_365d':
      /** Currently this is calculated on the backend to be inclusive of the current day */
      since = now.subtract(364, 'day');
      until = now;
      break;
    default:
      captureException(new Error(`'datePreset' does not exist: ${datePreset}`));
      until = now;
      since = until;
  }
  return {
    since: since.format('YYYY-MM-DD'),
    until: until.format('YYYY-MM-DD'),
  };
};

export function formatCustomDateLabel({
  since,
  until,
}: {
  since: string;
  until: string;
}) {
  const startDate = dayjs(since);
  const endDate = dayjs(until);

  // If user selects the same date for both start and end, just display a single date
  if (startDate.isSame(endDate, 'day')) {
    return startDate.format('MMM D, YYYY');
  }

  // For date ranges within the same year, just display the year once at the end of the custom label
  if (startDate.year() === endDate.year()) {
    return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
  }

  return `${startDate.format('MMM D, YYYY')} - ${endDate.format(
    'MMM D, YYYY'
  )}`;
}
