import { useState, useEffect } from 'react';
import NumberFlow from '@number-flow/react';
import Clock from 'src/assets/svgicons/line/clock.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { Icon } from '../Icon';
import { AriaButton } from '../Button';
import FeaturesModal from '../Modals/FeaturesModal';
import Sidebar from '../Sidebar';

const LimitedTimeOffer: React.FC = () => {
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);
  const { client } = trpc.useUtils();
  const { recordEvent } = useNewAnalyticsEvent();
  const userAndOrg = useUserAndOrganisation();
  const { LL } = useI18nContext();
  const createCheckoutUrl = trpc.stripe.getNewPortalCheckoutLink.useMutation();
  const { data: offerExpiryDate } = trpc.user.getOfferExpiryDate.useQuery(
    undefined,
    {
      enabled:
        userAndOrg.data?.organisation.billingState === 'free' ||
        userAndOrg.data?.organisation.billingState === 'trialExpired',
    }
  );

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!offerExpiryDate || !userAndOrg.data?.organisation.billingState) return;
    function calculateTimeLeft() {
      if (!offerExpiryDate) return;
      const now = new Date().getTime();
      const difference = offerExpiryDate.getTime() - now;

      if (
        difference > 0 &&
        userAndOrg.data?.organisation.billingState !== 'trialExpired'
      ) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          ),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000),
        });
      } else {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }
    }

    calculateTimeLeft();
    const interval = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(interval);
  }, [offerExpiryDate, userAndOrg.data?.organisation.billingState]);

  async function handleUpgrade() {
    void recordEvent({
      action: 'Clicked Upgrade Now On Limited Time Offer',
      target: 'Product Offer',
      metadata: {
        offerExpired,
      },
    });
    const plans = await client.stripe.getNewPlansForOrg.query();
    const sortedPlans = plans.sort((a, b) => a.paywallOrder - b.paywallOrder);
    const firstPlan = sortedPlans[0];
    if (firstPlan.stripeProductID) {
      createCheckoutUrl.mutate(
        {
          productID: firstPlan.stripeProductID,
          priceID: firstPlan.stripePrice,
        },
        {
          onSuccess: (response) => {
            window.location.href = response;
          },
        }
      );
    }
  }

  const offerExpired =
    (offerExpiryDate ? offerExpiryDate.getTime() < Date.now() : false) ||
    userAndOrg.data?.organisation.billingState === 'trialExpired';

  if (
    !offerExpiryDate ||
    !(
      userAndOrg.data?.organisation.billingState === 'free' ||
      userAndOrg.data?.organisation.billingState === 'trialExpired'
    )
  )
    return null;

  return (
    <Sidebar.Section>
      <div className="flex flex-col gap-2 rounded-lg border border-solid border-purple-300 bg-purple-50 px-3 py-4 text-primary">
        <div className="flex items-center gap-1">
          <Icon className="size-5">
            <Clock />
          </Icon>
          <p className="text-nowrap text-sm font-bold">
            {LL.upgrade.trialRemaining()}
          </p>
        </div>
        <p className="text-xs opacity-50">{LL.upgrade.limitedTimeOffer()}</p>
        <div className="flex items-center justify-center gap-1 text-center text-sm font-bold tabular-nums">
          <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
            <NumberFlow
              value={timeLeft.days}
              className="data-[kind=digit]:text-red-500"
            />
          </div>
          <span className="opacity-50">:</span>
          <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
            <NumberFlow
              value={timeLeft.hours}
              format={{ minimumIntegerDigits: 2 }}
            />
          </div>
          <span className="opacity-50">:</span>
          <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
            <NumberFlow
              value={timeLeft.minutes}
              format={{ minimumIntegerDigits: 2 }}
            />
          </div>
          <span className="opacity-50">:</span>
          <div className="grid h-10 flex-1 place-items-center rounded-md border border-solid border-secondary bg-white px-2 py-1">
            <NumberFlow
              value={timeLeft.seconds}
              format={{ minimumIntegerDigits: 2 }}
            />
          </div>
        </div>
        <AriaButton
          onPress={() => setShowFeaturesModal(true)}
          className="hidden w-full md:block"
          variant="gradient"
        >
          {LL.upgrade.upgradeNow()}
        </AriaButton>
        {/* Send them directly to Stripe if they're on mobile */}
        <AriaButton
          onPress={handleUpgrade}
          className="w-full md:hidden"
          variant="gradient"
        >
          {LL.upgrade.upgradeNow()}
        </AriaButton>

        <FeaturesModal
          title={
            offerExpired
              ? LL.upgrade.trialExpired()
              : timeLeft.days > 0
              ? LL.upgrade.offerExpiresInNDays({ n: timeLeft.days })
              : timeLeft.hours > 0
              ? LL.upgrade.offerExpiresInNHours({ n: timeLeft.hours })
              : LL.upgrade.offerExpiresSoon()
          }
          close={() => setShowFeaturesModal(false)}
          dismissable={!offerExpired}
          isOpen={showFeaturesModal}
        />
      </div>
    </Sidebar.Section>
  );
};

export default LimitedTimeOffer;
