import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  FC,
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  PropsWithRef,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

type SidebarComposite = {
  Content: FC<PropsWithRef<SidebarContentProps>>;
  Section: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<SidebarSectionProps> &
      React.RefAttributes<HTMLDivElement>
  >;
  NavLink: FC<SidebarNavLinkProps>;
  Button: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<SidebarButtonProps> &
      React.RefAttributes<HTMLButtonElement>
  >;
  Divider: FC;
  InteractiveNavItem: React.ForwardRefExoticComponent<
    React.PropsWithoutRef<SidebarItemProps> & React.RefAttributes<HTMLElement>
  >;
  ButtonWithActions: FC<SidebarButtonWithActionsProps>;
};

type SidebarProps = PropsWithChildren<{
  className?: string;
  side: 'left' | 'right';
}>;

const Sidebar: FC<SidebarProps> & SidebarComposite = ({
  className,
  children,
  side,
}) => {
  return (
    <div
      className={classNames(
        'border-solid border-purple-200 shadow',
        side === 'left' ? 'border-r' : 'border-l',
        className
      )}
    >
      {children}
    </div>
  );
};

type SidebarContentProps = PropsWithChildren<
  {
    className?: string;
  } & HTMLAttributes<HTMLDivElement>
>;

const SidebarContent = forwardRef<HTMLDivElement, SidebarContentProps>(
  ({ className, children, id }, ref) => {
    return (
      <div
        id={id}
        ref={ref}
        className={classNames('flex flex-auto flex-col gap-8', className)}
      >
        {children}
      </div>
    );
  }
);

SidebarContent.displayName = 'SidebarContent';

type SidebarSectionProps = PropsWithChildren<{
  title?: ReactNode | null;
  icon?: ReactNode;
  className?: string;
  direction?: 'horizontal' | 'vertical';
  contentClassName?: string;
}>;

const SidebarSection = forwardRef<HTMLDivElement, SidebarSectionProps>(
  (
    {
      title,
      children,
      icon,
      className,
      contentClassName,
      direction = 'vertical',
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={classNames(
          'flex',
          direction === 'vertical'
            ? 'flex-col gap-2.5'
            : 'flex-row items-center gap-6',
          className
        )}
      >
        {(icon || title) && (
          <div className="flex flex-row items-center gap-2.5 text-primary/50">
            {icon}
            <div className="flex-auto text-xs font-bold uppercase tracking-wider">
              {title}
            </div>
          </div>
        )}
        <div className={classNames('flex flex-col gap-1', contentClassName)}>
          {children}
        </div>
      </div>
    );
  }
);

SidebarSection.displayName = 'SidebarSection';

type SidebarNavLinkProps = PropsWithChildren<
  ComponentPropsWithoutRef<typeof NavLink>
>;

const SidebarNavLink: FC<SidebarNavLinkProps> = ({
  to,
  children,
  onClick,
  ...rest
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  return (
    <NavLink
      to={to}
      {...rest}
      onClick={(e) => {
        if (isActive || onClick) {
          // Prevent navigation if active or when passing an onClick cb
          e.preventDefault();
          onClick?.(e);
        }
      }}
    >
      {({ isActive }) => (
        <div
          className={classNames(
            'group flex cursor-pointer flex-row items-center gap-2.5 rounded-lg bg-none p-2 text-sm font-semibold text-primary hover:bg-purple-100',
            isActive ? 'sidebar-nav-active bg-purple-100' : ''
          )}
        >
          {children}
        </div>
      )}
    </NavLink>
  );
};

type SidebarButtonProps = PropsWithChildren<{
  isActive?: boolean;
}> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const SidebarButton = forwardRef<HTMLButtonElement, SidebarButtonProps>(
  ({ children, isActive, className, ...rest }, ref) => {
    return (
      <button
        {...rest}
        ref={ref}
        className={classNames(
          'group flex cursor-pointer flex-row items-center gap-2.5 rounded-lg bg-none p-2.5 text-sm font-semibold text-primary hover:bg-purple-100',
          isActive ? 'sidebar-nav-active bg-purple-100' : '',
          className
        )}
      >
        {children}
      </button>
    );
  }
);

SidebarButton.displayName = 'SidebarButton';

type SidebarItemProps = PropsWithChildren<{
  isActive?: boolean;
  actions: React.ReactNode | undefined;
  className?: string;
  to: React.ComponentProps<typeof NavLink>['to'];
}> &
  HTMLAttributes<HTMLDivElement>;

const SidebarInteractiveNavItem = forwardRef<HTMLElement, SidebarItemProps>(
  ({ children, isActive, className, actions, style, to, ...rest }, ref) => {
    return (
      <div
        {...rest}
        ref={ref as React.ForwardedRef<HTMLDivElement>}
        style={style}
        className={classNames(
          'group flex cursor-pointer flex-row items-center gap-1 rounded-lg bg-none text-sm font-semibold text-primary hover:bg-purple-100',
          isActive ? 'sidebar-nav-active bg-purple-100' : '',
          className
        )}
      >
        <NavLink
          to={to}
          className="flex min-w-0 flex-1 flex-row gap-2.5 p-2.5 pr-0"
        >
          {children}
        </NavLink>
        {actions && <div className="p-2.5 pl-0">{actions}</div>}
      </div>
    );
  }
);

SidebarInteractiveNavItem.displayName = 'SidebarInteractiveNavItem';

type SidebarButtonWithActionsProps = PropsWithChildren<{
  isActive?: boolean;
  actions: React.ReactNode | undefined;
  className?: string;
}> &
  Omit<HTMLAttributes<HTMLButtonElement>, 'className'>;

const SidebarButtonWithActions: FC<SidebarButtonWithActionsProps> = ({
  children,
  isActive,
  className,
  actions,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'group flex cursor-pointer flex-row items-center gap-1 rounded-lg bg-none text-sm font-semibold text-primary hover:bg-purple-100',
        isActive ? 'sidebar-nav-active bg-purple-100' : '',
        className
      )}
    >
      <button
        {...rest}
        className="flex min-w-0 flex-1 flex-row gap-2.5 p-2.5 pr-0"
      >
        {children}
      </button>
      {actions && <div className="p-2.5 pl-0">{actions}</div>}
    </div>
  );
};

const SidebarDivider: FC = () => {
  return <div className="h-[1px] bg-purple-200" />;
};

Sidebar.Section = SidebarSection;
Sidebar.NavLink = SidebarNavLink;
Sidebar.Content = SidebarContent;
Sidebar.Divider = SidebarDivider;
Sidebar.Button = SidebarButton;
Sidebar.InteractiveNavItem = SidebarInteractiveNavItem;
Sidebar.ButtonWithActions = SidebarButtonWithActions;

export default Sidebar;
