import { FC, useState } from 'react';
import { Icon } from 'src/components/Icon';
import Sidebar from 'src/components/Sidebar/Sidebar';
import PlaySquare from 'src/assets/svgicons/duotone/play-square.svg';
import File02 from 'src/assets/svgicons/duocolor/file-02.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import PlusCircle from 'src/assets/svgicons/duocolor/plus-circle.svg';
import { BaseButton } from 'src/components/Button';
import Hash01 from 'src/assets/svgicons/duocolor/hash-01.svg';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { SidebarDirectories } from 'src/components/Directories/SidebarDirectories/SidebarDirectories';
import LimitedTimeOffer from 'src/components/LimitedTimeOffer';
import CreateAssetCollectionModal from '../CreateAssetCollectionModal';

const AssetsSidebar: FC = () => {
  const { LL } = useI18nContext();
  const collections = trpc.storage.getAssetCollections.useQuery();
  const [showNewCollectionModal, setShowNewCollectionModal] = useState(false);
  const directoriesFeatureFlag = useFeatureFlag('DIRECTORIES');

  return (
    <Sidebar
      side="left"
      className="flex h-full w-full flex-col justify-between gap-6 overflow-y-auto bg-white p-5"
    >
      <Sidebar.Content className="min-h-0">
        <Sidebar.Section title={LL.navigation.assets()}>
          <Sidebar.NavLink end to="/assets">
            <Icon>
              <PlaySquare />
            </Icon>
            <span>{LL.assets.allAssets()}</span>
          </Sidebar.NavLink>

          <Sidebar.NavLink to="/assets/briefs">
            <Icon>
              <File02 />
            </Icon>
            <span>{LL.assets.briefAssets()}</span>
          </Sidebar.NavLink>
        </Sidebar.Section>
        {directoriesFeatureFlag ? (
          <SidebarDirectories basePath="/assets/d" />
        ) : (
          <Sidebar.Section
            className="min-h-[200px]"
            contentClassName="overflow-y-auto min-h-0"
            title={
              <div className="flex flex-auto flex-row items-center justify-between">
                {LL.library.collections()}
                <BaseButton
                  className="h-5 w-5 text-primary"
                  icon={
                    <Icon>
                      <PlusCircle />
                    </Icon>
                  }
                  onClick={() => setShowNewCollectionModal(true)}
                />
              </div>
            }
          >
            {collections.data?.map((collection) => (
              <Sidebar.NavLink
                key={collection.id}
                to={`/assets/collection/${collection.uuid}`}
              >
                <Icon className="h-4 w-4 shrink-0 text-primary/80 icon-path:stroke-[3]">
                  <Hash01 />
                </Icon>
                <span className="truncate">{collection.name}</span>
                <span className="items flex-auto pr-[5px] text-end text-purple-300 group-hover:text-primary group-[.sidebar-nav-active]:text-primary">
                  {collection._count.UserAssetsInAssetCollections}
                </span>
              </Sidebar.NavLink>
            ))}
          </Sidebar.Section>
        )}
        <LimitedTimeOffer />
      </Sidebar.Content>
      <CreateAssetCollectionModal
        onClose={() => setShowNewCollectionModal(false)}
        show={showNewCollectionModal}
      />
    </Sidebar>
  );
};

export default AssetsSidebar;
