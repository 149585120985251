import React, { FC } from 'react';
import { Heading, Text } from 'react-aria-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SlackColor from 'src/assets/svgicons/brands/SlackColor.svg';
import Calendar from 'src/assets/svgicons/duocolor/calendar.svg';
import Grid01 from 'src/assets/svgicons/duocolor/grid-01.svg';
import Menu01 from 'src/assets/svgicons/duocolor/menu-01.svg';
import Settings01 from 'src/assets/svgicons/duocolor/settings-01.svg';
import Signal03 from 'src/assets/svgicons/duocolor/signal-03.svg';
import Columns01 from 'src/assets/svgicons/duocolor/table.svg';
import Eye from 'src/assets/svgicons/duotone/eye.svg';
import FilterLines from 'src/assets/svgicons/duotone/filter-lines.svg';
import Plus from 'src/assets/svgicons/duotone/plus.svg';
import ArrowLeft from 'src/assets/svgicons/line/arrow-left.svg';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import ChevronLeft from 'src/assets/svgicons/line/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import RefreshCw02 from 'src/assets/svgicons/line/refresh-cw-02.svg';
import MagicBriefBolt from 'src/assets/svgicons/magicbrief/MagicBriefBolt.svg';
import { Icon } from 'src/components/Icon';
import Navigation from 'src/components/Navigation';
import { useI18nContext } from 'src/i18n/i18n-react';
import { InsightsAnalysisTableSkeleton } from 'src/pages/Insights/routes/InsightsAnalysis/InsightsAnalysis';
import { useInsightsLastSyncedTime } from 'src/pages/Insights/util/useInsightsJob';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import BaseLibraryToolbar from 'src/pages/LibraryV2/components/LibraryToolbar/components/BaseLibraryToolbar';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import { DashboardQuickActions } from '../../pages/Insights/routes/InsightsDashboard/components/DashboardQuickActions';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import { BaseButton } from '../Button';
import { AriaButton } from '../Button/Button';
import { MagicBriefButton } from '../Button/MagicBriefButton';
import {
  HorizontalTabbedRadio,
  HorizontalTabbedRadioGroup,
} from '../HorizontalTabbedRadio/HorizontalTabbedRadio';
import LibraryGrid from '../LibraryGrid/LibraryGrid';
import Avatar from '../Misc/Avatar';
import { SquareLoaders } from '../SquareLoaders';
import Toolbar from '../Toolbar';
import ToolbarTitleMenu from '../Toolbar/components/ToolbarTitleMenu';

export const AppWithNavLoader: FC = () => {
  const user = useUserAndOrganisation();

  return (
    <div className="relative flex h-full flex-col">
      {user.data ? (
        <Navigation />
      ) : (
        <Navigation.Base mobileMenu={null}>
          <div className="flex flex-row items-center">
            <Navigation.DefaultLead />
            <div className="hidden flex-row items-center gap-2 px-6 lg:flex">
              <Navigation.SkeletonLinks />
            </div>
          </div>

          <Navigation.Section>
            <Avatar className="h-9 w-9" />
          </Navigation.Section>
        </Navigation.Base>
      )}
      <div className="flex w-full grow items-center justify-center">
        <MagicBriefBolt className="size-6 animate-pulse text-primary" />
      </div>
    </div>
  );
};

export const SubRouteLoader: FC = () => {
  return (
    <div className="flex w-full grow items-center justify-center">
      <Icon className="animate-pulse text-purple-800">
        <MagicBriefBolt />
      </Icon>
    </div>
  );
};

export const AuthenticationLoader: FC = () => {
  return (
    <div className="flex h-screen animate-pulse flex-col items-center justify-center bg-white px-2">
      <div className="flex w-full max-w-md flex-col gap-5">
        <div className="h-10 w-full rounded-lg bg-purple-100" />
        <div className="flex flex-col items-center gap-1">
          <div className="h-4 w-[calc(100%-2rem)] rounded-lg bg-purple-100" />
          <div className="h-4 w-[calc(100%-2.5rem)] rounded-lg bg-purple-100" />
        </div>
        <div className="h-10 w-full rounded-lg bg-purple-100" />
        <div className="-mb-2 h-10 w-full rounded-lg bg-purple-100" />
        <div className="h-10 w-full rounded-lg bg-purple-100" />
        <div className="h-3 w-full place-self-center rounded-lg bg-purple-100" />
        <div className="mb-4 h-10 w-1/3 place-self-center rounded-lg bg-purple-100" />
        <div className="h-14 w-1/2 place-self-center rounded-lg bg-purple-100" />
      </div>
    </div>
  );
};

export const LibraryLoader: FC<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="flex h-10 items-center">
              <div className="h-6 w-32 animate-pulse rounded-lg bg-primary/10" />
            </div>
          }
        />
      )}
      <div className="px-5 py-4 md:px-9">
        <LibraryGrid isLoading isFetching={false} />
      </div>
    </div>
  );
};

export const BrandsLoader: FC<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  const { LL } = useI18nContext();

  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          mobileMenu={null}
          className="top-[72px]"
          title={
            <ToolbarTitleMenu
              icon={
                <Icon>
                  <Signal03 />
                </Icon>
              }
              title={LL.library.following()}
              link="/library/brands"
            />
          }
          actions={
            <div className="flex items-center gap-3 overflow-auto md:mr-4">
              <AriaButton
                variant="primary"
                icon={
                  <Icon>
                    <Plus />
                  </Icon>
                }
                isDisabled
              >
                {LL.brands.addBrand()}
              </AriaButton>
            </div>
          }
        />
      )}
      <BrandsTableSkeleton />
    </div>
  );
};

export const BrandsTableSkeleton = () => {
  const min = 75;
  const max = 250;

  return (
    <div className="px-5 py-4 md:px-9">
      <section className="flex h-[calc(100vh_-_8.5rem)] w-full gap-4 overflow-hidden border border-solid border-secondary bg-purple-50 md:h-[calc(100vh_-_12rem)] md:rounded-[10px]">
        <table className="h-fit w-full min-w-[800px] border-collapse border-spacing-0 text-purple-800">
          <thead className="h-[44px] bg-purple-50">
            <tr role="row">
              <td className="w-full border-b border-solid border-b-purple-200" />
            </tr>
          </thead>

          <tbody>
            {Array.from({ length: 10 }).map((_, i) => (
              <tr
                // eslint-disable-next-line react/no-array-index-key
                key={`${i}-loader`}
                className="bg-white p-4"
              >
                <td className="flex h-[71px] items-center gap-2 border-b border-solid border-b-purple-200 p-4">
                  <div className="size-9 animate-pulse rounded-full bg-slate-100" />
                  <div
                    className="h-5 animate-pulse rounded-md bg-slate-100"
                    style={{ width: Math.random() * (max - min) + min }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export const StoryboardsLoader: FC<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="h-6 w-32 animate-pulse rounded-lg bg-primary/10" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(32rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const InsightsLoader: FC = () => {
  return (
    <div className="flex w-full min-w-0 flex-col">
      {/* Insights Toolbar */}
      <div className="h-[84px] w-full animate-pulse border-b border-solid border-b-purple-200 bg-purple-50" />
      <div className="box-border flex h-full flex-auto flex-col gap-4 overflow-hidden px-5 py-4 lg:px-9">
        {/* Chart/Sort Metrics */}
        <div className="h-[87px] w-full animate-pulse rounded-[10px] border border-solid border-purple-200 bg-purple-50 shadow" />
        {/* Chart & Table */}
        <div className="rounded-xl border border-solid border-purple-200 bg-white shadow">
          <div className="relative flex h-[445px] min-h-[445px] w-full animate-pulse items-end gap-2 rounded-t-[inherit] bg-gray-50/50" />

          <div className="h-12 w-full animate-pulse border-y border-solid border-y-purple-200 bg-purple-50" />

          <ul className="rounded-b-[inherit] last:rounded-b-xl last:border-b-transparent [&>li]:border-b [&>li]:border-solid [&>li]:border-b-purple-100">
            {Array.from({ length: 15 }).map(() => (
              // eslint-disable-next-line react/jsx-key
              <li className="flex h-[56px] w-full animate-pulse items-center bg-gray-50/50" />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const wizardScoreHeaders = [
  'Hook Score',
  'Hold Score',
  'Click Score',
  'Buy Score',
];

export const InsightsDashboardLoader: FC = () => {
  const isDashboardEnabled = useFeatureFlag('INSIGHTS_DASHBOARD_ENABLED');

  if (!isDashboardEnabled) return <InsightsLoader />;

  return (
    <div className="w-full space-y-14 px-5 py-4 lg:px-9 lg:py-8">
      <DashboardQuickActions />
      {/* Performance */}
      <div className="space-y-4 @container">
        <div className="mb-3 flex flex-col items-start justify-start gap-4 @4xl:flex-row @4xl:items-end @4xl:justify-between @4xl:gap-6">
          <div>
            <Heading className="text-xl font-semibold text-primary">
              Performance
            </Heading>
            <Text className="text-sm font-medium text-[#917DD2]">
              Last 14 days
            </Text>
          </div>

          <div className="flex items-center gap-2 self-end">
            <MagicBriefButton
              className="group w-fit"
              variant="secondary"
              size="small"
              isDisabled
            >
              <Icon className="size-[18px] group-data-[disabled]:opacity-50">
                <SlackColor />
              </Icon>
              Share
            </MagicBriefButton>
            <MagicBriefButton
              className="w-fit"
              variant="secondary"
              size="small"
              isDisabled
            >
              <Icon className="size-[18px]">
                <Eye />
              </Icon>
              Metrics
            </MagicBriefButton>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="min-w-fit">
            <div
              className="mb-4 grid grid-cols-4 gap-4"
              style={{ minWidth: 'calc(280px * 4 + 1rem * 3)' }}
            >
              {[...Array(4)].map((_, index) => (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={`performance-${index}`}
                  className="h-[250px] min-w-[280px] rounded-[10px] bg-white p-4"
                >
                  <div className="mb-3 flex items-center justify-between">
                    <div className="h-5 w-20 animate-pulse rounded-md bg-purple-50" />
                    <div className="h-5 w-12 animate-pulse rounded-md bg-purple-50" />
                  </div>
                  <div className="mb-3 h-10 w-28 animate-pulse rounded-md bg-purple-50" />
                  <div className="mb-4 h-5 w-32 animate-pulse rounded-md bg-purple-50" />
                </div>
              ))}

              {wizardScoreHeaders.map((score) => (
                <div
                  key={score}
                  className="flex flex-col gap-3 rounded-xl border border-solid border-purple-200 bg-white p-4"
                >
                  <Text className="grow text-sm font-semibold text-[#917DD2]">
                    {score}
                  </Text>
                  <div className="mb-3 h-9 w-10 animate-pulse rounded-md bg-purple-50" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Recommendations */}
      <div className="embla space-y-4 @container">
        <div className="mb-3 flex flex-col items-start justify-start gap-4 sm:flex-row sm:items-end sm:justify-between sm:gap-6">
          <Heading className="text-xl font-semibold text-primary">
            Recommendations
          </Heading>

          <div className="flex items-center gap-2 self-end">
            <MagicBriefButton
              className="group w-fit"
              variant="secondary"
              size="small"
              isDisabled
            >
              <Icon className="size-[18px] group-data-[disabled]:opacity-50">
                <SlackColor />
              </Icon>
              Share
            </MagicBriefButton>
          </div>
        </div>

        <div className="relative w-full">
          <div className="flex gap-4 overflow-hidden">
            {/* Show two skeleton cards by default */}
            {[...Array(2)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`recommendation-${index}`}
                className="flex w-full shrink-0 flex-col gap-4 rounded-xl border border-solid border-purple-200 bg-white p-4 shadow-sm sm:flex-row lg:w-[calc(50%-8px)]"
              >
                {/* Image skeleton */}
                <div className="h-[195px] w-full animate-pulse rounded-md bg-purple-50 sm:h-full sm:w-[195px]" />

                <div className="flex min-w-0 flex-auto flex-col gap-4">
                  {/* Summary section skeleton */}
                  <div className="flex flex-col">
                    <div className="mb-1 h-4 w-16 animate-pulse rounded bg-purple-50" />
                    <div className="mt-2 space-y-2">
                      <div className="h-4 w-full animate-pulse rounded bg-purple-50" />
                      <div className="h-4 w-3/4 animate-pulse rounded bg-purple-50" />
                    </div>
                  </div>

                  {/* Recommendation section skeleton */}
                  <div className="flex flex-col">
                    <div className="mb-1 h-4 w-28 animate-pulse rounded bg-purple-50" />
                    <div className="mt-2 space-y-2">
                      <div className="h-4 w-full animate-pulse rounded bg-purple-50" />
                      <div className="h-4 w-5/6 animate-pulse rounded bg-purple-50" />
                      <div className="h-4 w-4/5 animate-pulse rounded bg-purple-50" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Winners */}
      <div className="embla space-y-4 @container">
        <div className="mb-3 flex flex-row items-end justify-between gap-6">
          <Heading className="text-xl font-semibold text-primary">
            Winners
          </Heading>

          <div className="flex items-center gap-2 self-end">
            <MagicBriefButton
              className="w-fit p-2"
              variant="secondary"
              size="small"
              isDisabled
            >
              <Icon className="size-[18px] text-primary/50 opacity-50">
                <ChevronLeft />
              </Icon>
            </MagicBriefButton>
            <MagicBriefButton
              className="w-fit p-2"
              variant="secondary"
              size="small"
              isDisabled
            >
              <Icon className="size-[18px] text-primary/50 opacity-50">
                <ChevronRight />
              </Icon>
            </MagicBriefButton>
          </div>
        </div>

        <div className="embla__viewport grow overflow-hidden">
          <div className="embla__container flex gap-4">
            {[...Array(7)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className="embla__slide flex flex-[0_0_100%] flex-col gap-3 overflow-hidden rounded-xl border border-solid border-purple-200 bg-white p-3 @[500px]:flex-[0_0_calc(50%-8px)] @[700px]:flex-[0_0_calc(33.333%-12px)] @[1050px]:flex-[0_0_calc(25%-12px)] @[1400px]:flex-[0_0_calc(20%-14px)] @[1800px]:flex-[0_0_calc(16.666%-14px)]"
              >
                <div className="aspect-square w-full animate-pulse rounded-lg bg-purple-50" />
                <div className="h-5 w-3/4 animate-pulse rounded bg-purple-50" />
                <ul className="grid grid-cols-[1fr_auto] gap-2">
                  {[...Array(5)].map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <React.Fragment key={index}>
                      {/* Metric name skeleton */}
                      <div className="h-4 w-16 animate-pulse rounded bg-purple-50" />
                      {/* Metric value skeleton */}
                      <div className="h-6 w-12 animate-pulse rounded bg-purple-50" />
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const InsightsAnalysisLoader: FC = () => {
  const { LL } = useI18nContext();
  const { accountUuid } = useParams();
  const { adAccount } = useInsightsAdAccount({ accountUuid });
  const subtitle = useInsightsLastSyncedTime();

  return (
    <div className="flex w-full min-w-0 flex-col">
      <Toolbar className="sticky z-100 box-border flex min-h-32 flex-col gap-2 pt-3 2xl:min-h-20">
        <div className="flex min-w-0 flex-auto flex-col justify-between px-5 lg:px-10 2xl:flex-row">
          <div className="flex min-w-0 flex-row items-center gap-4 truncate pb-4 text-primary lg:pb-3 xl:gap-8">
            <BaseButton
              className="inline-flex items-center justify-center rounded-md p-2 text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white xl:hidden"
              icon={
                <Icon className="block h-6 w-6">
                  <Menu01 />
                </Icon>
              }
            />
            <div className="flex min-w-0 flex-row gap-3 font-semibold text-primary">
              <div className="flex min-w-0 flex-col font-semibold text-primary">
                <span className="truncate">{adAccount.data?.name}</span>
                <span className="hidden text-xxs text-primary/40 md:block">
                  {subtitle}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-3 overflow-auto pb-3">
            <AriaButton
              data-intercom-target="insight_refresh"
              variant="white"
              isDisabled
              icon={
                <Icon>
                  <RefreshCw02 />
                </Icon>
              }
            />
            <AriaButton
              isDisabled
              variant="white"
              icon={
                <Icon>
                  <Settings01 />
                </Icon>
              }
            >
              Copilot Settings
            </AriaButton>

            <AriaButton
              variant="white"
              isDisabled
              icon={
                <Icon className="size-5.5 text-primary">
                  <Calendar />
                </Icon>
              }
            >
              <div className="flex items-center justify-between gap-3">
                <span className="hidden sm:block sm:whitespace-nowrap">
                  Last 14 Days
                </span>
                <Icon className="size-5 text-primary">
                  <ChevronDown />
                </Icon>
              </div>
            </AriaButton>
            <AriaButton
              data-intercom-target="insight_filter"
              variant="white"
              icon={
                <Icon>
                  <FilterLines />
                </Icon>
              }
              isDisabled
            >
              <span className="hidden lg:block">{LL.filters()}</span>
            </AriaButton>
            <AriaButton
              data-intercom-target="insight_customMetrics"
              variant="white"
              icon={
                <Icon>
                  <Eye />
                </Icon>
              }
              isDisabled
            >
              {LL.insights.card.tabs.metrics()}
            </AriaButton>
            <HorizontalTabbedRadioGroup
              data-intercom-target="insight_viewMode"
              groupAriaLabel="View"
              value="table"
              isDisabled
            >
              <HorizontalTabbedRadio aria-label="Table" value="table">
                <Icon>
                  <Columns01 />
                </Icon>
              </HorizontalTabbedRadio>
              <HorizontalTabbedRadio aria-label="Grid" value="grid">
                <Icon>
                  <Grid01 />
                </Icon>
              </HorizontalTabbedRadio>
            </HorizontalTabbedRadioGroup>
          </div>
        </div>
      </Toolbar>
      <div className="box-border flex h-full flex-auto flex-col gap-4 overflow-hidden px-5 py-4 lg:px-9">
        <InsightsAnalysisTableSkeleton />
      </div>
    </div>
  );
};

export const SlackLoader: FC = () => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      <BaseLibraryToolbar
        actions={null}
        mobileMenu={null}
        className="top-[72px]"
        title={
          <div className="h-6 w-32 animate-pulse rounded-lg bg-primary/10" />
        }
      />
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const TikTokLoader: FC = () => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      <BaseLibraryToolbar
        actions={null}
        mobileMenu={null}
        className="top-[72px]"
        title={
          <div className="h-6 w-32 animate-pulse rounded-lg bg-primary/10" />
        }
      />
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const BriefsLoader: FC<{
  showToolbar?: boolean;
}> = ({ showToolbar = true }) => {
  return (
    <div className="relative flex min-w-0 grow flex-col">
      {showToolbar && (
        <BaseLibraryToolbar
          actions={null}
          mobileMenu={null}
          className="top-[72px]"
          title={
            <div className="h-6 w-32 animate-pulse rounded-lg bg-primary/10" />
          }
        />
      )}
      <div className="flex flex-col gap-4 p-5 sm:grid sm:grid-cols-[repeat(auto-fill,minmax(24rem,auto))] lg:p-8">
        <SquareLoaders amount={20} className="h-36" />
      </div>
    </div>
  );
};

export const BriefEditorLoader: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="relative flex h-full flex-col">
      <Navigation.Base mobileMenu={null}>
        <Navigation.Section>
          <Navigation.Button
            onClick={() => {
              if (location.key === 'default') {
                navigate('/briefs', { replace: true });
              } else {
                navigate(-1);
              }
            }}
            icon={
              <Icon>
                <ArrowLeft />
              </Icon>
            }
          />
        </Navigation.Section>
        <div className="w-32 animate-pulse rounded-lg bg-white/10 text-white" />
        <Navigation.Section />
      </Navigation.Base>
      <div className="flex w-full flex-auto">
        <div className="flex w-full flex-auto flex-col bg-white px-4 py-8 sm:px-10">
          <div className="flex flex-col gap-4">
            <SquareLoaders amount={20} className="h-5.5" />
          </div>
        </div>
      </div>
    </div>
  );
};
