import { useState } from 'react';
import { HELP_LINK } from '@magicbrief/common';
import Building06 from 'src/assets/svgicons/duocolor/building-06.svg';
import LifeBuoy02 from 'src/assets/svgicons/duocolor/life-buoy-02.svg';
import Map01 from 'src/assets/svgicons/duocolor/map-01.svg';
import LogIn04 from 'src/assets/svgicons/duocolor/log-in-04.svg';
import SwitchHorizontal02 from 'src/assets/svgicons/duocolor/switch-horizontal-02.svg';
import UserCircle from 'src/assets/svgicons/duocolor/user-circle.svg';
import { Icon } from 'src/components/Icon';
import { MenuItem } from 'src/components/Menu/MenuItem';
import Avatar from 'src/components/Misc/Avatar';
import { useI18nContext } from 'src/i18n/i18n-react';
import { segment } from 'src/lib/segment';
import { useUserAndOrganisation } from '../../utils/useUserAndOrganisation';
import Spinner from '../Loaders/Spinner';
import { Menu, MenuItemGroup } from '../Menu/Menu';
import SwitchWorkspaceModal from './components/SwitchWorkspaceModal';
import { InsightsAdminModal } from './components/InsightsAdminModal/InsightsAdminModal';

const UserMenuDropdown: React.FunctionComponent = () => {
  const { LL } = useI18nContext();
  const [isWorkspaceModalOpen, setIsWorkspaceModalOpen] = useState(false);
  const [isInsightsAdminModalOpen, setIsInsightsAdminModalOpen] =
    useState(false);

  const user = useUserAndOrganisation();

  return (
    <>
      <SwitchWorkspaceModal
        currentOrg={user.data?.organisation}
        show={isWorkspaceModalOpen}
        onClose={() => setIsWorkspaceModalOpen(false)}
      />
      <InsightsAdminModal
        isOpen={isInsightsAdminModalOpen}
        onClose={() => setIsInsightsAdminModalOpen(false)}
      />
      <Menu
        as="button"
        className="rounded-full"
        items={
          <>
            {user.data?.user.email && (
              <MenuItemGroup>
                <div className="px-4 py-3 text-primary">
                  <p className="text-sm font-semibold">{LL.signedInAs()}</p>
                  <p className="truncate text-sm font-normal">
                    {user.data.user.email}
                  </p>
                </div>
              </MenuItemGroup>
            )}
            <MenuItemGroup>
              <div className="flex flex-row gap-3 px-4 py-3 text-primary">
                {user.data?.organisation.profilePicURL && (
                  <div className="size-10 items-center justify-center overflow-hidden rounded-lg">
                    <img
                      alt=""
                      className="h-full object-cover"
                      src={user.data.organisation.profilePicURL}
                    />
                  </div>
                )}
                <div className="shrink overflow-hidden">
                  <p className="text-sm font-semibold">
                    {LL.currentWorkspace()}
                  </p>
                  {user.isLoading ? (
                    <div className="text-primary">
                      <Spinner />
                    </div>
                  ) : (
                    <p className="truncate text-sm font-normal">
                      {user.data?.organisation.name}
                    </p>
                  )}
                </div>
              </div>
              <MenuItem.Button
                onClick={() => setIsWorkspaceModalOpen(true)}
                icon={
                  <Icon>
                    <SwitchHorizontal02 />
                  </Icon>
                }
              >
                {LL.workspaceSwitcher.title()}
              </MenuItem.Button>
            </MenuItemGroup>
            <MenuItemGroup>
              <MenuItem.ReactRouterLink
                icon={
                  <Icon>
                    <UserCircle />
                  </Icon>
                }
                to="/settings/profile"
              >
                {LL.yourProfile()}
              </MenuItem.ReactRouterLink>
              <MenuItem.ReactRouterLink
                icon={
                  <Icon>
                    <Building06 />
                  </Icon>
                }
                to="/settings/workspace"
              >
                {LL.workspace()}
              </MenuItem.ReactRouterLink>
              <button
                onClick={() => window.Beamer?.show()}
                id="product-updates"
                className="group flex h-10 w-full items-center justify-between gap-3 !bg-white px-4 py-2 text-sm font-semibold text-primary hover:!bg-purple-100"
              >
                <div className="flex items-center gap-3">
                  <Icon className="size-5">
                    <Map01 />
                  </Icon>
                  <span>{LL.productUpdates()}</span>
                </div>
                <div className="size-2 rounded-full bg-green-500" />
              </button>
              <MenuItem.ExternalLink
                icon={
                  <Icon>
                    <LifeBuoy02 />
                  </Icon>
                }
                href={HELP_LINK}
                onClick={() => void segment?.track('viewed_help')}
              >
                {LL.help()}
              </MenuItem.ExternalLink>
            </MenuItemGroup>
            {user.data?.user.undiesUser && (
              <MenuItemGroup>
                <MenuItem.Button
                  onClick={() => setIsInsightsAdminModalOpen(true)}
                >
                  Insights Account Admin
                </MenuItem.Button>
              </MenuItemGroup>
            )}
            <MenuItemGroup>
              <MenuItem.ReactRouterLink
                to="/logout"
                activeClassName="text-danger bg-red-50"
                inactiveClassName="text-danger"
                icon={
                  <Icon>
                    <LogIn04 />
                  </Icon>
                }
              >
                {LL.signOut()}
              </MenuItem.ReactRouterLink>
            </MenuItemGroup>
          </>
        }
      >
        <Avatar
          initial={user.data?.user.name.charAt(0)}
          src={user.data?.user.profilePicURL ?? undefined}
          className="size-9"
        />
      </Menu>
    </>
  );
};

export default UserMenuDropdown;
