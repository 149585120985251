import { cva, type VariantProps } from 'class-variance-authority';
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components';
import { Spinner } from './spinner';
import { cn } from '../lib/cn';
import { forwardRef } from 'react';

const buttonVariants = cva(
  [
    /* Base */
    'inline-flex w-fit items-center justify-center gap-2 whitespace-nowrap rounded-lg transition-colors duration-300 ease-out shadow-sm',
    /* Typography */
    'text-sm font-semibold tracking-[0.08px]',
    /* Disabled */
    'data-[disabled]:cursor-not-allowed',
    /* Resets */
    'focus:outline-none',
  ],
  {
    variants: {
      variant: {
        primary: [
          /* Base */
          'bg-primary text-white',
          /* Hover */
          'hover:bg-purple-900',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#E3DBFF]',
          /* Disabled */
          'disabled:opacity-50',
        ],
        secondary: [
          /* Base */
          'border border-purple-200 bg-white text-primary ',
          /* Hover */
          'hover:bg-purple-50',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#EEE9FF]',
          /* Disabled */
          'disabled:text-purple-300',
        ],
        text: [
          /* Base */
          'text-primary shadow-none',
          /* Hover */
          'hover:bg-purple-50',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#EEE9FF]',
          /* Disabled */
          'disabled:text-purple-300',
        ],
        cta: [
          /* Base */
          'bg-gradient-to-b from-[#AE62D2] via-[#934ACC] to-[#5F36EF] text-white ',
          /* Focus */
          'focus-visible:shadow-[0_0_0_4px_#E3DBFF]',
          /* Disabled */
          'disabled:bg-purple-300 disabled:bg-none',
        ],
        destructive: [
          /* Base */
          'border border-red-200 bg-red-100 text-red-700 ',
          /* Hover */
          'hover:bg-red-200',
          /* Focus */
          'focus-visible:border-red-700 focus-visible:shadow-[0_0_0_4px_#FEE2E2]',
          /* Disabled */
          'disabled:border-red-300 disabled:bg-red-50 disabled:text-red-300',
        ],
      },
      size: {
        small: 'h-9 px-3 py-2',
        medium: 'h-10 px-3.5 py-2.5',
        large: 'h-12 px-4 py-3',
      },
    },
  }
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {
  variant?: 'primary' | 'secondary' | 'text' | 'cta' | 'destructive';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'medium',
      loading = false,
      isDisabled,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <AriaButton
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        isDisabled={isDisabled || loading}
        {...props}
      >
        {(renderProps) => (
          <>
            {loading ? <Spinner className="size-4" /> : null}
            {typeof children === 'function' ? children(renderProps) : children}
          </>
        )}
      </AriaButton>
    );
  }
);
Button.displayName = 'Button';

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants };
export type { ButtonProps };
